@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/* ██████  ██████  ██       ██████  ██████ ██      ██    ██ ██      ██    ██ ██   ██ ██      ██    ██ ██      ██    ██ ██████ ██      ██    ██ ██      ██    ██ ██   ██ ██████  ██████  ███████  ██████  ██   ██ */
/* ████████ ███████ ██   ██ ████████ ██    ██       ██ ██     ██ ██    █████     ███      ██ ██    ██       ██ ██     ██ ██    ███████ ██   ██    ██ */
.u-taL { text-align: left !important; }

.u-taC { text-align: center !important; }

@media screen and (max-width: 568px) { .u-taC--xs-taL { text-align: left !important; } }

@media screen and (max-width: 768px) { .u-taC--sm-taL { text-align: left !important; } }

@media screen and (max-width: 1024px) { .u-taC--md-taL { text-align: left !important; } }

@media screen and (max-width: 1280px) { .u-taC--lg-taL { text-align: left !important; } }

.u-taR { text-align: right !important; }

.u-only-xs { display: none !important; }

@media screen and (max-width: 568px) { .u-only-xs { display: block !important; } }

.u-only-sm { display: none !important; }

@media screen and (max-width: 768px) { .u-only-sm { display: block !important; } }

@media screen and (max-width: 568px) { .u-only-sm { display: none !important; } }

.u-only-md { display: none !important; }

@media screen and (max-width: 1024px) { .u-only-md { display: block !important; } }

@media screen and (max-width: 768px) { .u-only-md { display: none !important; } }

@media screen and (max-width: 1024px) { .u-only-lg { display: none !important; } }

.u-under-sm { display: none !important; }

@media screen and (max-width: 768px) { .u-under-sm { display: block !important; } }

.u-not-under-sm { display: block !important; }

@media screen and (max-width: 768px) { .u-not-under-sm { display: none !important; } }

.u-overflow-hidden { overflow: hidden !important; }

.c-topicspath { max-width: 1170px; width: 100%; margin: 0 auto; padding: 0 5px 10px; text-align: left; }

@media screen and (max-width: 768px) { .c-topicspath { text-align: left; } }

.c-topicspath li { display: inline-block; font-size: 12px; color: #888888; }

@media screen and (max-width: 768px) { .c-topicspath li { font-size: 0.7em; } }

.c-topicspath li::after { content: "\f105"; font-family: "Font Awesome 5 Free"; font-weight: 600; display: inline-block; margin-left: 8px; }

.c-topicspath li:last-child::after { content: none; }

.c-topicspath li a { color: #888888; text-decoration: underline; text-underline-offset: 5px; }

.l-lowerCaption { position: relative; width: 100%; background-color: #F7F7F7; background-repeat: no-repeat; background-position: center; background-size: cover; overflow: hidden; padding: 40px 20px 53px; }

@media screen and (max-width: 568px) { .l-lowerCaption { padding: 30px 20px 43px; } }

.l-lowerCaption__title { color: #fff; max-width: 1170px; text-align: center; width: 100%; color: #333333; text-align: left; margin: 0 auto; padding-left: 5px; }

@media screen and (max-width: 768px) { .l-lowerCaption__title { width: 100%; } }

.l-lowerCaption__title > small { color: #D00022; font-size: 18px; font-weight: 600; }

#privacy .p-analysisTool { margin-top: 20px; border: 1px solid #000; padding: 20px; }

#privacy .p-analysisTool__heading { font-size: 22px; margin-bottom: 15px; }

@media screen and (max-width: 568px) { #privacy .p-analysisTool__heading { font-size: 18px; margin-bottom: 12px; } }

#privacy .p-analysisTool p:last-child { margin-bottom: 0; }
